var component = require('../../../lib/js/component.js');
var utils = require("../../../lib/js/utils.js");
var moreToggle = require('../../../templates/shared/more-links.js');

function ProductSummaryV2() {

    var _initCount = 0;
    var _originalOrder = {};

    var _galleryLookupTable = {
        "community": $(".ProductSummaryGalleryv2"),
        "qmi": $(".ProductSummaryQMIGalleryv2")
    };
    
    var _expandMethods = setupExpandGalleryCards();

    var _view = "";

    this.initialize = function (element, options) {
        var $window = $(window);
        this.lastItem = null;
        this.hasExperienceMessage = $('.ExperienceMessage').length ? true : false;
        this.setExperienceModalButtons();

        // Fix issue of community opening in experience modal
        $('.experience-modal-button').on('click', function(e) {
            e.preventDefault();
            $('#modal-experience').modal('show');
        });

        // ...More toggle for communities
        moreToggle.initialize(this.$element);

        // See More toggle for QMIs
        $('.more-button').off().on("click", function() {
            var $this = $(this);
            var $parent = $this.parent();
            if($parent.hasClass('collapse-more')) {
                $parent.removeClass('collapse-more');
                $this.text('See Less');       
            }
            else {
                $parent.addClass('collapse-more');
                $this.text('See More');     
            }
        });
        this.handleGalleryView(null,"community");
        
        if (_initCount == 0) { // only setup once
            _initCount += 1;
            this.$element.on("pfv2-filter-change", this.handleFilter.bind(this));
            this.$element.on("pf-view-change", this.handleGalleryView.bind(this));

            _galleryLookupTable["community"].find(".community-expand").on("click", utils.debounce(_expandMethods.expandSummary, 250, false));
            _galleryLookupTable["qmi"].find(".qmi-expand").on("click", utils.debounce(_expandMethods.expandSummary, 250, false));
        }

        var communitiesCount = $('.ProductSummary__community.card').length;
        var qmiCount = $('.QMIGalleryCard').length;
        $('#ProductFilterV2').trigger('product-filter-v2-initialized', [communitiesCount, qmiCount]);
        $window.on("resize", utils.debounce(setupCloseMatchBreak, 250, false));
    }

    // If the experience modal is not on the page, the button clicks should navigate the user to the appropriate community/qmi page without the experience message popup.
    this.setExperienceModalButtons = function() {
        var $viewCommunityButtons = this.$element.find('.ProductSummary__buttons [data-target="#modal-experience"]');

        if (!this.hasExperienceMessage) {
            $viewCommunityButtons.each( function() {
                var $this = $(this);
                $this.attr("href", $this.data('href'));
                $this.removeAttr('data-toggle');
            });
        }
    }

    this.handleGalleryView = function(event,view) {
        Object.values(_galleryLookupTable).forEach(function(val){
            val.hide()
        });
        _view = view;
        _galleryLookupTable[view].show();
    }

    this.handleFilter = function(event, searchResult, filterValues, isFiltered, productType) {
        var resetOrder = !isFiltered;

        var formattedProductType = productType.replaceAll(/[.:()-\s]/g, "").toLowerCase();
        if (formattedProductType === "communities") {
            this.orderCardsByAttr(".ProductSummary__community.card", "data-community-id", searchResult, resetOrder);
        }
        else if (formattedProductType === "quickmoveins") {
            this.orderCardsByAttr(".QMIGalleryCard", "data-lot-number", searchResult, resetOrder);
            this.orderCardsByAttr(".QMIGridCard", "data-lot-number", searchResult, resetOrder);
        }

        _expandMethods.resetSetup();
        
        setupCloseMatchBreak();
    }

    this.orderCardsByAttr = function (selector, attrSelector, searchResult, resetOrder) {
        var communityCaseProp = 'id';
        var qmiCaseProp = 'lotBlock';
        var resetCaseProp = 'attrValue';

        var originalOrderKey = selector.replaceAll(/[.:()-]/g, "");
        if (resetOrder && _originalOrder[originalOrderKey]) 
            searchResult = _originalOrder[originalOrderKey];

        if (!searchResult) return;

        var $targetCards = $(selector);
        var $prev = $targetCards.first().prev();
        var $parent = $targetCards.first().parent();

        if (!_originalOrder[originalOrderKey]) {
            _originalOrder[originalOrderKey] = $targetCards.map(function(i, card) { 
                var cardData = $(card).data();
                return { attrValue: cardData.lotNumber || cardData.communityId } 
            }).toArray();
        }

        $targetCards.detach();
        var orderedElements = [];
        var handleExactCloseMatchClasses = setupExactCloseClassHandler();
        searchResult.forEach(function(item) {
            var attrValue = item[communityCaseProp] || item[qmiCaseProp] || item[resetCaseProp];
            var matchingCard = $targetCards.filter('[' + attrSelector + '="' + attrValue + '"]')[0];

            if (!matchingCard) return;

            handleExactCloseMatchClasses(matchingCard, item);

            orderedElements.push(matchingCard);
        });

        if ($prev.length > 0) 
            $(orderedElements).insertAfter($prev);
        else
            $parent.prepend(orderedElements);

    };

    function setupExactCloseClassHandler() {
        var exactHeaderAdded = false;
        var closeHeaderAdded = false;
        var exactHeader = 'exact-match-header';
        var closeHeader = 'close-match-header';

        return function (matchingCard, item) {
            var isFirstMatch = false;

            matchingCard.classList.remove(exactHeader, closeHeader);
    
            if (item.hasOwnProperty('isExactMatch')) {
                if (item.isExactMatch) {
                    if (!exactHeaderAdded) {
                        isFirstMatch = true;
                        exactHeaderAdded = true;
                    }
                } else {
                    if (!closeHeaderAdded) {
                        isFirstMatch = true;
                        closeHeaderAdded = true;
                    }
                }
            }
            
            if (isFirstMatch) {
                if (item.isExactMatch)
                    matchingCard.classList.add(exactHeader);
                else
                    matchingCard.classList.add(closeHeader);
            } 
        }
    }

    function setupCloseMatchBreak() {
        var containerClass = "";
        if (_view == "community") containerClass = ".ProductSummaryV2__communityContainer";
        if (_view == "qmi") containerClass = ".ProductSummaryV2__qmiContainer";

        var $exactMatchHeader = $(containerClass + " .exact-match-label");
        var $closeMatchHeader = $(containerClass + " .close-match-label");
        var $exactMatchCards = $(".exact-match-header:visible");
        if ($exactMatchCards.length) {
            $exactMatchHeader.insertBefore($exactMatchCards.first());
            $exactMatchHeader.removeClass("d-none");
        } else {
            $exactMatchHeader.addClass("d-none");
        }

        var $closeMatchCards = $(".close-match-header:visible");
        if ($closeMatchCards.length) {
            $closeMatchHeader.insertBefore($closeMatchCards.first());
            $closeMatchHeader.removeClass("d-none");
        } else {
            $closeMatchHeader.addClass("d-none");
        }
    }

    function setupExpandGalleryCards() {
        resetSetup();

        return {
            expandSummary: expandSummary,
            resetSetup: resetSetup
        };

        function expandSummary(e) {
            var $expandTrigger = $(e.currentTarget);

            setupCloseMatchBreak();
            $expandTrigger.closest('.collapsed-card-container').removeClass('collapsed-card-container');
        }

        function resetSetup() {
            var isTablet = window.innerWidth >= 768 && window.innerWidth <= 1024;
            var $productSummaryDefaultShowing = $('.ProductSummaryV2__default-text');
            if (!isTablet) {
                // Changes Text for both QMIs and Community
                $productSummaryDefaultShowing.each(function(_, el) {
                    var $element = $(el);
                    $element.html($element.html().replace('6','5'));                    
                })
            } else {
                // For Tablet the collapsed summary threshold is 6 cards not 5. Because this depends on the 
                // browser window width it is being handled in the Javascript
                var $qmiCardContainer = $('.ProductSummaryV2__qmiContainer');
                if( $qmiCardContainer.find('.QMIGalleryCard').length == 6) {
                    $qmiCardContainer.closest('.collapsed-card-container').removeClass('collapsed-card-container');
                }

                var $communityCardContainer = $('.ProductSummaryV2__communityContainer');
                if($communityCardContainer.find('.card').length == 6) {
                    $communityCardContainer.closest('.collapsed-card-container').removeClass('collapsed-card-container');
                }
            }
        }        
    }
}

module.exports = component(ProductSummaryV2);
